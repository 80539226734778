.button {
  font-family: $font-primary;
  padding: $grid / 2;
  border: 2px solid black;
  text-decoration: underline;
  background-color: white;
  text-align: center;
  width: max-content;
  height: max-content;
  user-select: none;
  cursor: pointer;
  @include font-size(l);
  position: relative;
  z-index: 1;
  color: black;
  transition: color 0.6s ease;
  overflow: hidden;

  &:active {
    border: 2px solid white;
    outline: 3px solid black;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    background: black;
    height: 200%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
    transform: scaleY(0%);
    transition: transform 0.6s ease;
  }

  &:hover {
    color: white;
    transition: color 0.5s ease;

    &::after {
      transform: scaleY(100%);
      transition: transform 0.5s ease;
    }
  }

  &--subtle {
    font-family: $font-primary;
    padding: $grid / 2;
    border: 2px solid black;
    text-decoration: underline;
    background-color: white;
    text-align: center;
    user-select: none;
    height: max-content;
    cursor: pointer;
    @include font-size(l);

    &:hover {
      outline: 2px solid black;
    }

    &:active {
      border: 2px solid white;
    }
  }

  &--exit {
    font-family: $font-primary;
    padding: $grid / 2;
    border: 2px solid black;
    text-decoration: underline;
    background-color: white;
    color: black;
    text-align: center;
    user-select: none;
    height: max-content;
    cursor: pointer;
    @include font-size(l);

    &:hover {
      outline: 4px solid white;
    }

    &:active {
      outline: 4px solid black;
    }
  }

  &--large {
    @include font-size(xl);
    padding: $grid * 0.8 $grid;
  }

  &--small {
    @include font-size(m);
  }
}
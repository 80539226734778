.collage-photo {
  position: relative;
  @include media-query(md){
    position: absolute;
  }

  &__image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;

    @include media-query(md){
      width: 50vmin;
      user-select: none;
      z-index: 1;
      //border: 2px solid black;
      //border-radius: 5px;
    }
    //filter: hue-rotate(90deg) brightness(1.25);
  }

  &__cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 4px; //No idea why??? makes the images overlap each other perfectly.

    width: 100%;
    max-height: 200px;
    object-fit: cover;

    @include media-query(md){
      background-image: radial-gradient(transparent 55%, rgba(255, 255, 255, 0.936) 75%);
      background-size: 2px 2px;
      width: 50vmin;
      //border-radius: 5px;
      //border: 2px solid black;
    }
    pointer-events: none;
    z-index: 2;

    background-image: linear-gradient( to bottom, white 0%, transparent 5%, transparent 95%, white 100% ), radial-gradient(transparent 55%, rgba(255, 255, 255, 0.192) 75%);
    background-size: 100% 100%, 8px 8px;

  }

  &__hover-image {

    width: 100%;
    max-height: 200px;
    object-fit: cover;

    position: absolute;
    z-index: 3;
    left: 0;
    cursor: pointer;
    bottom: 4px; //No idea why??? makes the images overlap each other perfectly.
    @include media-query(md){
      //border-radius: 5px;
      width: 50vmin;
    }
  }

}
.Chonlachat-Srisompetch {
  &__collage-photo {

    @include media-query(md){
      top: calc(50%);
      left: calc(50%);
      transform: translate(-50%, -50%) translate(-55vmin, 30vmin);
      z-index: 2;
    }
  }
}
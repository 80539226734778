.sketchpad {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 60;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  overflow-y: scroll;

  padding: $grid * 1 0 0 0;
  display: grid;
  gap: $grid;

  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content min-content min-content;
  }

  @media (min-width: 1090px) {
    bottom: 0;
    padding: $grid * 2;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: min-content max-content min-content;
  }

  &__canvas {
    @media (min-width: 680px) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      justify-self: center;
    }

    @media (min-width: 1090px) {
      grid-column: 2 / 4;
      grid-row: 1 / 4;
      align-self: center;
      justify-self: start;
    }
  }

  &__submission-form {
    display: none;
  }

  &__brush-slider {

    @media (min-width: 680px) {
      justify-self: center;
      width: 400px;
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }

    @media (min-width: 1090px) {
      grid-column: 1 / 2;
      width: 330px;
      grid-row: 1 / 2;
    }

    background-color: white;
    border-radius: $grid / 2;
    padding: $grid / 2;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &__label {
      font-size: $font-primary;
      user-select: none;
      position: relative;
      text-align: left;
      z-index: 2;
      @include font-size(m);
      mix-blend-mode: difference;
      color: white;
    }

    &__preview {
      position: absolute;
      z-index: 1;
      right: 15%;
      top: 38%;
      transform: translate(50%, -50%);
      border-radius: 50%;
    }

    input {
      position: relative;
      z-index: 2;
      width: 100%;
    }
   
  }

  //NOTE: This is a duplicate of _react-color-picker.scss, that's where the styles are overriden.
  &__color-picker {
    @media (min-width: 680px) {
      justify-self: right;
      grid-column: 1 / 2;
      grid-row: 3 / 6;
    }

    @media (min-width: 1090px) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  &__feedback-target-dropdown {

    @media (min-width: 680px) {
      justify-self: left;
      height: max-content;
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }

    @media (min-width: 1090px) {
      justify-self: center;
      width: 330px;
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    background-color: white;
    font-family: $font-primary;
    padding: $grid / 2;
    text-align: center;
    border-radius: $grid / 2;
    @include font-size(m);

    select {
      @include font-size(m);
      font-family: $font-primary;
      text-align: center;
    }

    p {
      margin: 0;
    }
  }

  &__submit-button {

    @media (min-width: 680px) {
      justify-self: left;
      grid-column: 2 / 3;
      grid-row: 5 / 6;
    }

    @media (min-width: 1090px) {
      grid-column: 3 / 4;
      grid-row: 3 / 4;
      align-self: end;
    }
  }

  &__cancel-button {
    @media (min-width: 680px) {
      justify-self: left;
      width: initial !important;
      grid-column: 2 / 3;
      grid-row: 4 / 5;
    }

    @media (min-width: 1090px) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      align-self: flex-start;
    }
  }

}
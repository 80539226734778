.James-Hancock {

  &__collage-photo {
    @include media-query(md){
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%) translate(-10vmin, 10vmin);
    }
  }

  &__title {
    z-index: 40;
    background: white;
    border: 2px solid black;
    padding: 5px;
  }

}
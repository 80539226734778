.artist-profile {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-query(md){
    flex-direction: row;
    gap: $grid;
  }

  &__image {
    width: 100%;
    max-width: 360px;
    margin: 0 auto $grid;
    max-height: 360px;

    @include media-query(md){
      max-height: 23vw;
      margin: 0;
      width: 23vw;
      margin-bottom: 0;
    }
  
  }

  &__content {
    padding: 0 $grid;

    @include media-query(md){
      padding: 0;
    }

    font-family: $font-primary;
    @include font-size(l);
  }
}
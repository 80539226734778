.about-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  background-color: white;
  display: flex;
  justify-content: center;

  &__title {
    margin-top: $grid * 6;

    @include media-query(md){
      margin-top: $grid * 4;
    }
    
    @include font-size(xxxl);
  }

  &__content {
    text-align: center;
    width: 100%;

    padding: 0 $grid;
    
    @include media-query(md){
      width: 800px;
      margin: 0 auto;
    }
  }

  &__paragraph {
    @include font-size(l);
    margin: $grid 0;
    text-align: left;
  }

  &__logo {
    width: 100px;
    margin-bottom: $grid;

    @include media-query(md){
      margin-bottom: $grid* 2;
    }
    
  }

  &__subtitle {
    margin-top: $grid * 2;
    @include font-size(xxl);
  }

  &__list {
    list-style: square;
    > li {
      padding-bottom: $grid / 2;
    }
  }
}
$grid: 20px;

$color--white: #FDFFFC;
$color--grey: #52534c;
$color--dark-grey: #2B2C28;
$color--black: #0D0F0F;
 
$primary-color: $color--white;
$secondary-color: $color--dark-grey;

/** Transitions */
$ease-out: cubic-bezier(0.5, 1, 0.89, 1);

/** Media Query Breakpoints */

$media-query-breakpoints: (
  //Anything less than 600px is Mobile Portrait
  sm: 480px, //Mobile Landscape
  md: 800px, //Tablet Landscape
  //Beyond tablat landscape show gallery navigation button
  lg: 1200px //Desktop
);

/** Typography Variables
 
 @font-face {
  font-family: 'EBGaramond';
  font-display: swap;
  src: url('/fonts/EBGaramond/EBGaramond-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'EBGaramond';
  font-display: swap;
  src: url('/fonts/EBGaramond/EBGaramond-Italic-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-style: italic;
}*/

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@500&display=swap');

$font-primary: 'Consolas', 'Inconsolata', monospace;

$font-weights: ("regular": 400, "medium": 500, "bold": 700);
 
$mobile-font-sizes: (
  xxs: 8px,
  xs: 10px,
  s: 12px,
  m: 20px,
  l: 20px,
  xl: 22px,
  xxl: 28px,
  xxxl: 32px
);

$tablet-font-sizes: (
  xxs: 8px,
  xs: 10px,
  s: 12px,
  m: 20px,
  l: 20px,
  xl: 24px,
  xxl: 28px,
  xxxl: 48px
);

$desktop-font-sizes: (
  xxs: 10px,
  xs: 12px,
  s: 14px,
  m: 18px,
  l: 22px,
  xl: 24px,
  xxl: 30px,
  xxxl: 48px
);
.Omar-Hamouda {
  &__collage-photo {
    @include media-query(md){
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%) translate(50vmin, 0vmin);
    z-index: 2;
    }
  }

}
.Puppyuck {

  &__collage-photo {
    @include media-query(md){
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%) translate(-49vmin, -22.5vmin);
    z-index: 2;
    }
  }

}
.header {

  @include media-query(md){
    position: absolute;
    top: 0;
    right: $grid;
    z-index: 6;
    pointer-events: none;
  }

  &__button {
    margin-top: $grid;
  }

  &__logo {
    margin-top: $grid;
    width: 100%;

    @include media-query(sm){
      width: 350px;
    }

    @include media-query(md){
      width: 530px;
    }
  }

  &__title {
    text-align: center;
  }

  &__description {

    @include font-size(m);
    text-align: center;

    > p:first-child {
      margin-top: $grid;
    }

    > p:last-child {
      margin-bottom: $grid;
    }

    > p {
      margin: 4px 0;
    }

    @include media-query(md){
      margin: 0 0 0 auto;
      display: block;
      width: max-content;
      padding: $grid 0 $grid $grid;
      
      background: white;
      text-align: center;
      pointer-events: initial;

      > p:first-child, > p:last-child {
        margin: 0;
      }
    }
  }
}
.showcase {
  &__content {
    width: 100%;
    margin-bottom: $grid * 2;

    @include media-query(md){
      width: 800px;
    }
  }

  &__artwork-title {
    display: block;
    margin: $grid * 5 0 $grid * 1;
    padding: 0 $grid;
    overflow-wrap: break-word;

    @include media-query(md) {
      width: max-content;
      padding: 0;
      margin: $grid * 4 auto $grid * 1;
    }

    @include font-size(xxxl);
    padding-bottom: 4px;
    border-bottom: 4px solid black;
  }

  &__paragraph {
    padding: 0 $grid;
    @include media-query(md){
      padding: 0;
    }
    margin-bottom: $grid * 2;
    @include font-size(l);
  }

  &__author-subtitle {
    padding: 0 $grid;
    @include media-query(sm){
      padding: 0;
      text-align: center;
    }
  }

  &__video-embed {
    max-width: 640px;
    display: block;
    margin: 0 auto;

    &__subtitle {
      text-align: center;
      font-family: $font-primary;
      @include font-size(l);
      padding-top: 4px;
      color: $color--dark-grey;
      margin-bottom: $grid * 1;

      @include media-query(md){
        margin-bottom: $grid * 2;
      }
    }
  }

  &__center-button {
    text-align: center;
    margin-bottom: $grid * 2;
    @include media-query(md){ 
      margin-bottom: $grid * 3;
    }
  }

  &__list {
    list-style: square;
    > li {
      margin-bottom: $grid;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__four-buttons {
    display: grid;
    gap: $grid;
    margin-bottom: $grid * 2;
    justify-items: center;

    @include media-query(sm){
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content;
    }
    
  }

  &__left-button {
    @include media-query(sm){
    justify-self: right;
    }
  }
  &__right-button {
    @include media-query(sm){
    justify-self: left;
    }
  }

  &__image {
    width: 100%;
  }

  &__image-description {
    font-family: $font-primary;
    width: 80%;
    margin: 4px auto $grid;
    text-align: center;
    @include font-size(l);
  }

  &__two-images {
    display: flex;
    gap: $grid;
    flex-direction: column;

    @include media-query(sm){
    
    flex-direction: row;
    > img {
      width: 50%;
    }
    
    }
  }

  &__gap {
    height: $grid;
  }
}
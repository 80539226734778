:root {
  --rainbowHue: 120;
}

html, body { 
  margin: 0; 
  background-color: #fff;
}

canvas { 
  display: block; 
}

p, li, h1, h2, h3, h4, h5 {
  font-family: $font-primary;
}

.bold {
  font-weight: bold;
}

::selection {
  color: $color--white;
  background: $color--black;
}

.center {
  position: absolute;
  color: #bbb;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tall {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 4000px;
}

.App {
  @include media-query(md){
    overflow: hidden;
    height: 100vh;
  }
}
.guestbook {
  position: fixed;
  z-index: 30;
  bottom: 0;
  right: $grid;
  
  &__tab {
    cursor: pointer;
    background: white;
    padding: $grid / 2 $grid;
    font-family: $font-primary;
    @include font-size(l);
    font-weight: bold;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border: 2px solid black;
    border-bottom: none;
    text-decoration: underline;
    user-select: none;

    &:hover {
      background: #ccc;
    }
  }

  &__options {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $grid;
    background: whitesmoke;
    gap: $grid;
    border: 2px solid black;
    border-bottom: none;
  }

  &__view-submissions-button {
    width: 15ch;
  }

  &__sketchpad-button {
    display: none;

    @include media-query(md) {
      display: block;
    }
  }

  &__sketchpad-disclaimer {
    @include font-size(m);
    text-align: center;
    color: #444;
    width: 200px;
    margin: 0;
    @include media-query(md) {
      display: none;
    }
  }
}